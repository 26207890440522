import { graphql } from 'gatsby';
import React from 'react';

import Footer from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import PostCard from '../components/PostCard';
import Wrapper from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  PostFeed,
  PostFeedRaise,
  SiteDescription,
  SiteHeader,
  MovedSiteHeader,
  SiteHeaderContent,
  SiteMain,
  SiteTitle,
} from '../styles/shared';
import { PageContext } from './post';
import config from '../../content/website-config';

import Meta from '../components/Meta';

interface TagTemplateProps {
  pathContext: {
    slug: string;
  };
  pageContext: {
    tag: string;
  };
  data: {
    tagYaml: {
      id: string;
      label: string;
      description: string;
      image?: {
        childImageSharp: {
          fluid: any;
        };
      };
    };
    allMarkdownRemark: {
      totalCount: number;
      edges: {
        node: PageContext;
      }[];
    };
  };
}

const Tags: React.SFC<TagTemplateProps> = (props) => {
  const tag = props.pageContext.tag;
  const { edges, totalCount } = props.data.allMarkdownRemark;

  const tagData = props.data.tagYaml;

  const name = tagData.label || tagData.id;

  return (
    <IndexLayout>
      <Meta
        title={name + ' - ' + config.title}
        description={tagData && tagData.description}
        path={props.pathContext.slug}
        image={tagData && tagData.image && tagData.image}
      />
      <Wrapper>
        <header
          className={`${tagData && tagData.image ? '' : 'no-cover'}`}
          css={[MovedSiteHeader, SiteHeader, outer]}
          style={{
            backgroundImage:
              tagData && tagData.image
                ? `url('${tagData.image.childImageSharp.fluid.src}')`
                : '',
          }}
        >
          <div css={inner}>
            <SiteNav isHome={false} />
            <SiteHeaderContent>
              <SiteTitle>{name}</SiteTitle>
              <SiteDescription>
                {tagData && tagData.description ? (
                  tagData.description
                ) : (
                  <>
                    {/* A collection of {totalCount > 1 && `${totalCount} posts`}
                    {totalCount === 1 && `1 post`}
                    {totalCount === 0 && `No posts`} */}
                  </>
                )}
              </SiteDescription>
            </SiteHeaderContent>
          </div>
        </header>
        <main id="site-main" css={[SiteMain, outer]}>
          <div css={inner}>
            <div css={[PostFeed, PostFeedRaise]}>
              {edges.map(({ node }) => {
                const tags = node.frontmatter.tags.map((t) => t.id);
                const hasTag = tags.indexOf(tagData.id) >= 0;
                if (!hasTag) {
                  return null;
                }

                return <PostCard key={node.fields.slug} post={node} />;
              })}
            </div>
          </div>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default Tags;

export const pageQuery = graphql`
  query($tag: String) {
    tagYaml(id: { eq: $tag }) {
      id
      description
      label
      image {
        childImageSharp {
          fluid(maxWidth: 3720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          excerpt
          timeToRead
          frontmatter {
            title
            tags {
              id
              label
            }
            date
            image {
              childImageSharp {
                fluid(maxWidth: 1240) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            author {
              id
              bio
              avatar {
                children {
                  ... on ImageSharp {
                    fixed(quality: 100) {
                      src
                    }
                  }
                }
              }
            }
          }
          fields {
            layout
            slug
          }
        }
      }
    }
  }
`;
